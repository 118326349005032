<template>
  <div class="d-flex">
    <applied-filters :filters="computedFilters" :allow-remove="false" />

    <span v-if="filters.length > limit">
      {{ `+${filters.length - limit} more` }}
    </span>
  </div>
</template>

<script>
// Import helper functions
import filterConverter from "@/helpers/filterConverter"

// Import child components
import AppliedFilters from "@/components/common/AppliedFilters.vue"

// Export the SFC
export default {
  // Name of the component
  name: "PreviewFilters",

  // Register children components
  components: {
    AppliedFilters
  },

  // Accept incoming data from parent
  props: {
    // The applied filters array
    filters: {
      type: Object,
      required: true
    },

    // The limit of filters to display
    limit: {
      type: Number,
      required: false,
      default: 3
    }
  },

  // Define local data variables
  data: () => ({
    // The computed filters
    computedFilters: []
  }),

  /**
   * When the component is created
   *
   * @returns {void}
   */
  async created() {
    // Store the filters
    const filters = []

    // Loop through each filter key
    for (const key of Object.keys(this.filters)) {
      // If the value is an array
      if (Array.isArray(this.filters[key])) {
        // Loop through each value
        for (const item of this.filters[key]) {
          filters.push(await filterConverter(key, item))
        }
      }
      // Otherwise
      else {
        filters.push(await filterConverter(key, this.filters[key]))
      }
    }

    // Set the computed filters
    this.computedFilters = filters.slice(0, this.limit)
  }
}
</script>
